import Vuex from 'vuex';

const createStore = () => {
  return new Vuex.Store({
    state: {
      headerHeight: null,
      activeSectionId: null,
      componentsHeightIntervals: null,
      locale: process.env.buildLocale
    },
    mutations: {
      setHeaderHeight(state, headerHeight) {
        state.headerHeight = headerHeight;
      },
      setActiveSectionId(state, activeSectionId) {
        state.activeSectionId = activeSectionId;
      },
      setComponentsHeightIntervals(state, componentsHeightIntervals) {
        state.componentsHeightIntervals = componentsHeightIntervals;
      }
    },
    getters: {
      getLocale: state => state.locale,
      getHeaderHeight: state => state.headerHeight,
      getActiveSectionId: state => state.activeSectionId,
      getComponentsHeightIntervals: state => state.componentsHeightIntervals
    }
  });
};

export default createStore;
